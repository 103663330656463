<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="accountconfigs"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.account_id`]="{ item }">
            <v-btn
              :to="{ name: 'accountconfigs.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.account.code }} - {{ item.account.name }}
            </v-btn>
          </template>

          <template v-slot:[`item.accounttype_id`]="{ item }">
            {{ item.accounttype ? item.accounttype.name : null }}
          </template>

          <template v-slot:[`item.paymentmethod_id`]="{ item }">
            {{ item.paymentmethod ? item.paymentmethod.name : null }}
          </template>

          <template v-slot:[`item.currency_id`]="{ item }">
            {{ item.currency ? item.currency.name : null }}
          </template>

          <template #[`item.currencies`]="{ item }">
            <currency-list :currency-ids="item.currencies" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <accountconfigs-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountconfigsMixin from "@/modules/accountconfigs/mixins/AccountconfigsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AccountconfigsForm from "@/modules/accountconfigs/components/Form.vue";
import type { DataTableHeader } from "vuetify";
import CurrencyList from "@/modules/currencies/components/CurrencyList.vue";

@Component({
  components: {
    CurrencyList,
    ActiveIcon,
    AccountconfigsForm,
  },
})
export default class AccountconfigsList extends Mixins(AccountconfigsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "account.code", value: "account_id" },
      { text: "account.type", value: "accounttype_id" },
      { text: "payment.method", value: "paymentmethod_id" },
      { text: "currency", value: "currency_id" },
      { text: "accepted.currencies", value: "currencies" },
    ];
    this.addDTHeaders(arHeaders).delDTHeader("name").delDTHeader("active");

    this.index();
  }
}
</script>
